import { z } from 'zod'

import { ActivityLinkTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectCreatedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_created',
  z.object({
    package: ActivityLinkTargetSchema,
  })
)
