import { z } from 'zod'

import {
  ReviewRoundNumberSchema,
  ReviewStatusSchema,
} from '../property.models.ts'
import { ReviewKeySchema } from '../review.models.ts'
import { RoundStatusSchema } from '../round.models.ts'
import { ReviewIdSchema, RoundIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ReviewStatusChangedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'review_status_changed',
    z
      .object({
        reviewId: ReviewIdSchema,
        reviewKey: ReviewKeySchema,
        reviewStatus: ReviewStatusSchema,
        roundId: RoundIdSchema,
        roundNumber: ReviewRoundNumberSchema,
        roundStatus: RoundStatusSchema,
      })
      .merge(ActivityLinkTargets('project'))
  )
