import { z } from 'zod'

import { ActivityLinkTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectServiceAddedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_service_added',
    z.object({
      service: ActivityLinkTargetSchema,
      project: ActivityLinkTargetSchema,
    })
  )
