import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { OfferIdSchema } from '../unique-id.models.ts'
import { SurveyFeedbackSchema } from './common.ts'
import { SurveyTypeSchema } from './survey-types.ts'

export const [
  OfferAcceptedReasons,
  OfferAcceptedReasonSchema,
  OfferAcceptedReasonEnum,
] = EnumBuilder(
  'great-brand',
  'good-project-for-portfolio',
  'aligns-with-values-and-culture',
  'lucrative-budget',
  'high-confidence-in-doing-an-excellent-job',
  'industry-matches-experience',
  'existing-relationship-with-customer',
  'other'
)

export type OfferAcceptedReason = z.infer<typeof OfferAcceptedReasonSchema>

export const OfferAcceptedResponsesSchema = z.discriminatedUnion('reason', [
  z.object({
    reason: OfferAcceptedReasonSchema.extract(['other']),
    feedback: SurveyFeedbackSchema,
  }),
  z.object({
    reason: OfferAcceptedReasonSchema.exclude(['other']),
    feedback: SurveyFeedbackSchema.optional(),
  }),
])

export type OfferAcceptedResponses = z.infer<
  typeof OfferAcceptedResponsesSchema
>

export const OfferAcceptedSurveyDetailsSchema = z.object({
  surveyType: SurveyTypeSchema.extract(['offer-accepted']),
  offerId: OfferIdSchema,
  responses: OfferAcceptedResponsesSchema,
})
