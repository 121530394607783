import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { OfferIdSchema } from '../unique-id.models.ts'
import { SurveyFeedbackSchema } from './common.ts'
import { SurveyTypeSchema } from './survey-types.ts'

export const [
  OfferDeclinedReasons,
  OfferDeclinedReasonSchema,
  OfferDeclinedReasonEnum,
] = EnumBuilder(
  'cannot-make-deadline',
  'insufficient-budget',
  'not-taking-new-projects',
  'unclear-scope',
  'not-interested',
  'other'
)

export type OfferDeclinedReason = z.infer<typeof OfferDeclinedReasonSchema>

export const OfferDeclinedResponsesSchema = z.discriminatedUnion('reason', [
  z.object({
    reason: OfferDeclinedReasonSchema.extract(['other']),
    feedback: SurveyFeedbackSchema,
  }),
  z.object({
    reason: OfferDeclinedReasonSchema.exclude(['other']),
    feedback: SurveyFeedbackSchema.optional(),
  }),
])

export type OfferDeclinedResponses = z.infer<
  typeof OfferDeclinedResponsesSchema
>

export const OfferDeclinedSurveyDetailsSchema = z.object({
  surveyType: SurveyTypeSchema.extract(['offer-declined']),
  offerId: OfferIdSchema,
  responses: OfferDeclinedResponsesSchema,
})
