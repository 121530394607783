import { z } from 'zod'
import { ProjectIdSchema } from '../unique-id.models.ts'
import { SurveyFeedbackSchema, SurveyRatingSchema } from './common.ts'
import { SurveyTypeSchema } from './survey-types.ts'

export const ProjectCompletedMakerReviewsBrandResponsesSchema = z.object({
  brandRating: SurveyRatingSchema,
  feedback: SurveyFeedbackSchema.optional(),
})

export type ProjectCompletedMakerReviewsBrandResponses = z.infer<
  typeof ProjectCompletedMakerReviewsBrandResponsesSchema
>

export const ProjectCompletedMakerReviewsBrandSurveyDetailsSchema = z.object({
  surveyType: SurveyTypeSchema.extract([
    'project-completed-maker-reviews-brand',
  ]),
  projectId: ProjectIdSchema,
  responses: ProjectCompletedMakerReviewsBrandResponsesSchema,
})
