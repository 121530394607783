export const AssertEnvVar = (key: string, value?: string): string => {
  const env = process.env[key]

  if (env === undefined) {
    throw new Error(`Value of process.env.${key} is undefined`)
  }

  if (value !== undefined && env !== value) {
    throw new Error(
      `Value of process.env.${key} "${env}" does not match "${value}"`
    )
  }

  return env
}
