import { z } from 'zod'

import { AnyUrnSchema, UserIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectOfferSentActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_offer_sent',
  z.object({
    offers: z
      .object({
        urn: AnyUrnSchema,
        acceptorId: UserIdSchema,
      })
      .array(),
    project: ActivityLinkTargetSchema,
  })
)
