import { z } from 'zod'
import { NonEmptyStringSchema } from '../property.models.ts'

export const SurveyFeedbackMaxLength = 4096
export const SurveyFeedbackSchema = NonEmptyStringSchema(
  SurveyFeedbackMaxLength
)
export type SurveyFeedback = z.infer<typeof SurveyFeedbackSchema>

export const SurveyRatingMin = 1
export const SurveyRatingMax = 5
export const SurveyRatingSchema = z
  .number()
  .int()
  .min(SurveyRatingMin)
  .max(SurveyRatingMax)
