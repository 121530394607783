import { z } from 'zod'

import { ActivityLinkTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectFileAddedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_file_added',
  z.object({
    file: ActivityLinkTargetSchema,
    project: ActivityLinkTargetSchema,
  })
)
