import { z } from 'zod'

import { EnumBuilder } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  SubjectIdSchema,
  UserIdSchema,
  WatchIdSchema,
} from './unique-id.models.ts'

/**
 * WatchEventType
 */
export const [WatchEventTypes, WatchEventTypeSchema, WatchEventTypeEnum] =
  EnumBuilder(
    'brand-onboarding-completed',
    'comment',
    'internal-project-declined-by-all-makers-DEPRECATED',
    'maker-onboarding-completed',
    'new-custom-project',
    'new-job-offer',
    'offer-expired',
    'project-accepted-by-maker',
    'project-assigned',
    'project-matched-brand',
    'project-matched-maker',
    'project-needs-offers'
  )
export type WatchEventType = z.infer<typeof WatchEventTypeSchema>

/**
 * WatchPreferenceType
 */
export const [
  WatchPreferenceTypes,
  WatchPreferenceTypeSchema,
  WatchPreferenceTypeEnum,
] = EnumBuilder('opt-in', 'opt-out')
export type WatchPreferenceType = z.infer<typeof WatchPreferenceTypeSchema>

/**
 * WatchNotificationType
 */
export const [
  WatchNotificationTypes,
  WatchNotificationTypeSchema,
  WatchNotificationTypeEnum,
] = EnumBuilder('email')
export type WatchNotificationType = z.infer<typeof WatchNotificationTypeSchema>

/**
 * WatchDomainSelectModel
 */
export const WatchDomainSelectModelSchema = z.object({
  watchId: WatchIdSchema,
  userId: UserIdSchema,
  watchEventType: WatchEventTypeSchema,
  subjectId: SubjectIdSchema,
  watchPreferenceType: WatchPreferenceTypeSchema,
  watchNotificationType: WatchNotificationTypeSchema,
})

export type WatchDomainSelectModel = z.infer<
  typeof WatchDomainSelectModelSchema
>

/**
 * WatchDomainInsertModel
 */
export const WatchDomainInsertModelSchema =
  WatchDomainSelectModelSchema.partial({
    watchId: true,
  })

export type WatchDomainInsertModel = z.infer<
  typeof WatchDomainInsertModelSchema
>

/**
 * WatchDomainUpdateModel
 */
export const WatchDomainUpdateModelSchema = WatchDomainSelectModelSchema.omit({
  watchId: true,
}).partial()

export type WatchDomainUpdateModel = z.infer<
  typeof WatchDomainUpdateModelSchema
>

/**
 * WatchDomainSelectModelFilter
 */
export const WatchDomainSelectModelFiltersSchema = z
  .object({
    watchId: ModelFilterSchema(WatchIdSchema),
    userId: ModelFilterSchema(UserIdSchema),
    subjectId: ModelFilterSchema(SubjectIdSchema),
    watchEventType: ModelFilterSchema(WatchEventTypeSchema),
    watchPreferenceType: ModelFilterSchema(WatchPreferenceTypeSchema),
    watchNotificationType: ModelFilterSchema(WatchNotificationTypeSchema),
  })
  .partial()

export type WatchDomainSelectModelFilters = z.infer<
  typeof WatchDomainSelectModelFiltersSchema
>
