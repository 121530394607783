import { z } from 'zod'

import { type ActivityType, ActivityTypeSchema } from './activity-types.ts'

export const ActivityTypeAndDataSchema = <
  Type extends ActivityType,
  DataSchema extends z.ZodTypeAny,
>(
  activityType: Type,
  dataSchema: DataSchema
) =>
  z.object({
    activityType: ActivityTypeSchema.extract([activityType]),
    data: dataSchema,
  })
