import { z } from 'zod'

import { ProjectSubmittedStatusSchema } from '../project.models.ts'
import { ActivityLinkTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectSubmittedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_submitted',
  z.object({
    project: ActivityLinkTargetSchema,
    status: ProjectSubmittedStatusSchema,
  })
)
