import { z } from 'zod'

import { ExternalUserTypeSchema } from '../user.models.ts'
import { ActivityLinkTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ServiceNoteAddedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'service_note_added',
  z.object({
    service: ActivityLinkTargetSchema,
    project: ActivityLinkTargetSchema,
    noteType: ExternalUserTypeSchema,
  })
)
