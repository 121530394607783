import { z } from 'zod'

import { ActivityLinkTargetSchema } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectOfferExpiredActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_offer_expired',
    z.object({
      offer: ActivityLinkTargetSchema,
    })
  )
