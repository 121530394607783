import { EnumBuilder } from '@mntn-dev/utility-types'
import type { z } from 'zod'

export const [SurveyTypes, SurveyTypeSchema, SurveyTypeEnum] = EnumBuilder(
  'project-completed-brand-reviews-maker',
  'project-completed-maker-reviews-brand',
  'offer-accepted',
  'offer-declined'
)
export type SurveyType = z.infer<typeof SurveyTypeSchema>

export const ActiveSurveyTypes = [
  'offer-accepted',
  'offer-declined',
] as const satisfies SurveyType[]

export const OfferSurveyTypes = [
  'offer-accepted',
  'offer-declined',
] as const satisfies SurveyType[]

export type OfferSurveyType = (typeof OfferSurveyTypes)[number]

export const ProjectSurveyTypes = [
  'project-completed-brand-reviews-maker',
  'project-completed-maker-reviews-brand',
] as const satisfies SurveyType[]

export type ProjectSurveyType = (typeof ProjectSurveyTypes)[number]
