import { z } from 'zod'
import { OfferAcceptedSurveyDetailsSchema } from './offer-accepted.ts'
import { OfferDeclinedSurveyDetailsSchema } from './offer-declined.ts'
import { ProjectCompletedBrandReviewsMakerSurveyDetailsSchema } from './project-completed-brand-reviews-maker.ts'
import { ProjectCompletedMakerReviewsBrandSurveyDetailsSchema } from './project-completed-maker-reviews-brand.ts'

export const SurveyDetailsSchema = z.discriminatedUnion('surveyType', [
  OfferAcceptedSurveyDetailsSchema,
  OfferDeclinedSurveyDetailsSchema,
  ProjectCompletedBrandReviewsMakerSurveyDetailsSchema,
  ProjectCompletedMakerReviewsBrandSurveyDetailsSchema,
])

export type SurveyDetails = z.infer<typeof SurveyDetailsSchema>
